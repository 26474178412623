<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div class="container">
    <div class="row my-2 align-items-center">
      <div class="col-md-6">
        <h3>Coupon Wise Report</h3>
      </div>
      <div class="col-md-3">
        <DatePicker
          format="yyyy-MM-dd"
          type="date"
          name="from_date"
          v-model="search.from_date"
          id="from_date"
          placeholder="Select From Date"
          :clearable="false"
          style="width: 100%"
        ></DatePicker>
      </div>
      <div class="col-md-3">
        <DatePicker
          format="yyyy-MM-dd"
          type="date"
          name="to_date"
          v-model="search.to_date"
          id="to_date"
          placeholder="Select To Date"
          :clearable="false"
          style="width: 100%"
        ></DatePicker>
      </div>
    </div>
    <PurchaseOverViewVue :search="search" :coupon="$route.params.coupon" />
    <CourseWiseSalesOverView :search="search" :coupon="$route.params.coupon" />
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BLink } from "bootstrap-vue";
import PurchaseOverViewVue from "./partials/PurchaseOverView.vue";
import CourseWiseSalesOverView from "./partials/CourseWiseSalesOverView.vue";
export default {
  data() {
    return {
      search: {
        from_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to_date: new Date(),
        search_data: "",
        paginate: 10,
        page: 1,
      },
    };
  },
  computed: {},
  components: {
    PurchaseOverViewVue,
    CourseWiseSalesOverView,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
  },
};
</script>

<style></style>
